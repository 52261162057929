import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Alert,
  AlertTitle,
  useMediaQuery,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import theme from "lib/theme";
import { formatDate } from "utils/helpers";
import { Pickup } from "types/pickup";
import { Link as RouterLink } from "react-router-dom";
import { PickupStatus } from "./PickupStatus";
import usePickups from "hooks/pickups/usePickups";

interface PickupTableProps {
  pickups: Pickup[];
  errorMessage: null;
  isLoading: boolean;
  getPickups: () => void;
  hasNextPage: boolean;
  deletePickup: (pickupUuid: string) => Promise<void>;
  markPickupUnenrolled: (pickupUuid: string) => void;
  incrementCurrentPage: () => void;
}

export default function PickupCards({
  pickups,
  errorMessage,
  isLoading,
  deletePickup,
  markPickupUnenrolled,
  getPickups,
}: PickupTableProps) {
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  if (errorMessage) {
    return (
      <Box
        sx={{
          mt: 6,
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage || "There was a problem loading your pickups."}
        </Alert>
      </Box>
    );
  }

  const isEmpty = pickups?.length === 0;

  if (!isLoading && isEmpty) {
    return (
      <Box
        sx={{
          mt: 6,
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          borderRadius: "4px",
          px: 2,
          py: 12,
        }}
      >
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <Box>
            <LocalShippingOutlinedIcon
              sx={{ fontSize: "64px", color: "text.disabled" }}
            />
          </Box>

          <Typography variant="h6" fontWeight={"bold"}>
            Schedule your first pickup!
          </Typography>
          <Typography mt={1} maxWidth={"420px"} textAlign="center">
            Get started by clicking below.
          </Typography>
          <Button
            component={RouterLink}
            variant="outlined"
            sx={{ mt: 2 }}
            to="/pickups/new"
          >
            Create Pickup
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mt: 6,
      }}
    >
      <Grid container spacing={2}>
        {pickups.map((pickup) => (
          <Grid item xs={12} sm={6} md={4} key={pickup.id}>
            <PickupCard
              pickup={pickup}
              deletePickup={deletePickup}
              markPickupUnenrolled={markPickupUnenrolled}
              getPickups={getPickups}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function PickupCard({
  pickup,
  deletePickup,
  markPickupUnenrolled,
  getPickups,
}: {
  pickup: Pickup;
  deletePickup: Function;
  markPickupUnenrolled: Function;
  getPickups: Function;
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { getCod } = usePickups();

  const handleDelete = async () => {
    await deletePickup(pickup.id);
    getPickups();
    setConfirmOpen(false);
  };

  const downloadCod = async () => {
    try {
      const codUrl = await getCod(pickup?.uuid || "");
      const response = await fetch(codUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "pickup_certificate_of_destruction.pdf";
      a.click();
    } catch (error) {
      console.error("Error downloading COD:", error);
    }
  };

  return (
    <Card sx={{ width: "100%", minHeight: "225px", position: "relative" }}>
      <CardContent>
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            display: "flex",
            gap: 1,
          }}
        >
          {pickup.state === "pending_confirmation" && (
            <>
              <IconButton
                size="small"
                component={RouterLink}
                to={`/pickups/${pickup.uuid}/edit`}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => setConfirmOpen(true)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Box>
        <Typography variant="h6" component="div">
          {(pickup.scheduledDate && formatDate(pickup.scheduledDate)) ||
            "Select Date"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Pickup Number: {pickup.orderNumber}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Address:{" "}
          {(pickup.location && pickup.location.address) || "Add address"}{" "}
          {(pickup.location && pickup.location.address2) || ""} <br></br>
          {(pickup.location && pickup.location.city) || ""}
          {pickup.location && pickup.location.state
            ? `, ${pickup.location.state}`
            : ""}{" "}
          {pickup.location && pickup.location.zipcode
            ? ` ${pickup.location.zipcode}`
            : ""}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Point of Contact: {pickup.contactName || "Add contact"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Status: <PickupStatus pickup={pickup} />
        </Typography>
        {pickup.state === "complete" && (
          <Box sx={{ pt: 2 }}>
            <Button
              variant="contained"
              size="small"
              onClick={downloadCod}
              sx={{ mr: 2 }}
            >
              Download COD
            </Button>
            <Button
              variant="contained"
              size="small"
              component={RouterLink}
              to={`/assets?filter[]=state,is,retired&filter[]=pickup_id,is_any_of,${pickup.id}`}
            >
              View assets
            </Button>
          </Box>
        )}
      </CardContent>

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Pickup"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this pickup?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
